$(document).ready(function() {

    $('.btn-scroll').click(function() {
        var $link = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(""+$link+"").offset().top - 200
        }, 1000);
        if ($('.navbar-collapse').hasClass('in')){
            $('.navbar-collapse').removeClass('in');
            $('.navbar-collapse').attr('aria-expanded', false);
            $('.navbar-collapse').css({ 'height': '1px'});
        }
        return false;
    });

    $('.contact-submit').click(function(){
        var form = $(this).closest('form'),
            email = form.find('input[name=contact_email]'),
            message = form.find('textarea[name=contact_message]'),
            $errors = 0;
        form.find('input').removeClass('is-invalid');
        form.find('textarea').removeClass('is-invalid');
        form.find('.invalid-feedback').hide();
        if (email.val()==='') {
            email.addClass('is-invalid');
            email.parents('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }else{
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            var address = email.val();
            if(reg.test(address) === false) {
                email.addClass('is-invalid');
                email.parents('.form-group').find('.invalid-feedback').show();
                $errors ++;
            }
        }
        if (message.val()==='') {
            message.addClass('is-invalid');
            message.parents('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }
        if ($errors ===0){
            var actions = form.attr('action');
            $(this).prop('disabled',true);
            $(this).find('.fa-spinner').show();

            $.ajax({
                type:"POST",
                url:actions,
                data:form.serialize(),
                dataType: 'json',
                success: function(data){
                    $('.contact-submit').prop('disabled',false);
                    $('.contact-submit').find('.fa-spinner').hide();
                    $('#contacts .alert-success').fade();
                },
                error: function(data){
                    $('.contact-submit').prop('disabled',false);
                    $('.contact-submit').find('.fa-spinner').hide();
                    $('#contacts .alert-danger').show();
                }
            });

        }
        return false;
    });

    $('.booking-submit').click(function(){
        var booking_form = $(this).closest('form'),
            booking_name = booking_form.find('input[name=name]'),
            booking_email = booking_form.find('input[name=contact_email]'),
            booking_phone = booking_form.find('input[name=phone_number]'),
            $errors = 0;

        $('.booking-form  .alert-danger').hide();
        $('.booking-form .alert-success').hide();

        booking_form.find('.form-control').removeClass('is-invalid');
        booking_form.find('.invalid-feedback').hide();
        if (booking_email.val()==='') {
            booking_email.addClass('is-invalid');
            booking_email.parent().find('.invalid-feedback').show();
            $errors ++;
        }else{
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            var address = booking_email.val();
            if(reg.test(address) === false) {
                booking_email.addClass('is-invalid');
                booking_email.closest('.form-group').find('.invalid-feedback').show();
                $errors ++;
            }
        }
        if (booking_name.val()==='') {
            booking_name.addClass('is-invalid');
            booking_name.closest('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }
        if (booking_phone.val()==='') {
            booking_phone.addClass('is-invalid');
            booking_phone.closest('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }
        if ($errors ===0){
            var actions = booking_form.attr('action');
            $(this).prop('disabled',true);
            $(this).find('.fa-spinner').show();

            $.ajax({
                type:"POST",
                url:actions,
                data:booking_form.serialize(),
                dataType: 'json',
                success: function(data){
                    $('.booking-submit').prop('disabled',false);
                    $('.booking-submit').find('.fa-spinner').hide();
                    $('.booking-form  .alert-danger').hide();
                    $('.booking-form .alert-success').show();
                },
                error: function(data){
                    $('.booking-submit').prop('disabled',false);
                    $('.booking-submit').find('.fa-spinner').hide();
                    $('.booking-form .alert-success').hide();
                    $('.booking-form .alert-danger').show();
                }
            });

        }
        return false;
    });

});
